<template>
  <div class="menu-box" ref="menu-box" @click="toggleActive">
    <span class="menu"></span>
  </div>
</template>

<script>
export default {
  methods: {
    toggleActive() {
      const menuBox = this.$refs["menu-box"];
      const sideBarNavBar = document.querySelector(".menu-wrapper");
      sideBarNavBar.classList.toggle("show");
      menuBox.classList.toggle("active");
      if (menuBox.classList.contains("active")) {
        menuBox.classList.remove("close");
      } else {
        menuBox.classList.add("close");
        setTimeout(() => {
          menuBox.classList.remove("close");
        }, 1000);
      }
    }
  }
};
</script>

<style scoped>
.menu-box {
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.menu {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}

.menu::before,
.menu::after {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(0);
  transform-origin: center center;
}

.menu::before {
  box-shadow: 0 -10px 0 0 #fff, 0 10px 0 0 #fff;
}

.menu-box.active .menu::before {
  animation: menuActiveShadow 0.2s linear forwards,
    menuActive 0.2s 0.2s linear forwards, rotateBefore 0.2s 0.4s linear forwards;
}

.menu-box.active .menu::after {
  animation: menuActive 0.2s 0.2s linear forwards,
    rotateAfter 0.2s 0.4s linear forwards;
}

.menu-box.close .menu::after {
  animation: rotateCloseAfter 0.2s linear forwards,
    menuClose 0.2s 0.4s linear forwards;
}

.menu-box.close .menu::before {
  animation: rotateCloseBefore 0.2s linear forwards,
    menuClose 0.2s 0.4s linear forwards,
    menuCloseShadow 0.2s 0.6s linear forwards;
}
</style>

<style>
/* Open Animations */
@keyframes menuActive {
  from {
    height: 5px;
    width: 5px;
  }
  to {
    height: 28px;
    width: 3px;
  }
}

@keyframes menuActiveShadow {
  0% {
    box-shadow: 0 -10px 0 0 #fff, 0 10px 0 0 #fff;
  }
  100% {
    box-shadow: 0 -10px 0 0 transparent, 0 10px 0 0 transparent;
  }
}

@keyframes rotateAfter {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

@keyframes rotateBefore {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

/* Close Animations */
@keyframes menuClose {
  from {
    width: 3px;
    height: 28px;
  }
  to {
    width: 5px;
    height: 5px;
  }
}

@keyframes menuCloseShadow {
  0% {
    box-shadow: 0 -10px 0 0 transparent, 0 10px 0 0 transparent;
  }
  100% {
    box-shadow: 0 -10px 0 0 #fff, 0 10px 0 0 #fff;
  }
}

@keyframes rotateCloseAfter {
  from {
    height: 28px;
    width: 3px;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  to {
    height: 28px;
    width: 3px;
    transform: translate(-50%, -50%) rotate(0);
  }
}

@keyframes rotateCloseBefore {
  from {
    height: 28px;
    width: 3px;
    box-shadow: 0 -10px 0 0 transparent, 0 10px 0 0 transparent;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  to {
    height: 28px;
    width: 3px;
    box-shadow: 0 -10px 0 0 transparent, 0 10px 0 0 transparent;
    transform: translate(-50%, -50%) rotate(0);
  }
}
</style>
