<template>
  <div id="app">
    <TopNavBar />
    <router-view />
  </div>
</template>
<script>
import TopNavBar from "./components/TopNavBar/TopNavBar";

export default {
  components: {
    TopNavBar
  }
};
</script>
<style lang="css">
* {
  padding: 0px;
  margin: 0px;
  outline: none;
  font-family: "Vazir FD";
  box-sizing: border-box;
}
input {
  //font-family: "Vazir FD";
}
a {
  text-decoration: none;
}
body {
  /*font-family: "Vazir FD";*/
  direction: rtl;
}

/*button {*/
/*  font-family: "Vazir FD";*/
/*}*/

:root {
  --blue: #070d59;
}

.my-custom-toast-class {
  margin-right: auto !important;
  margin-left: unset !important;
}
</style>
