import Vue from "vue";
import Vuex from "vuex";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";

Vue.use(Vuex);
Vue.use(VueLoading, {
  loader: "bars",
  color: "#080F66"
});

export default new Vuex.Store({
  state: {
    status: "",
    message: "",
    token: localStorage.getItem("token") || "",
    two_step_verification: "",
    user: JSON.parse(localStorage.getItem("user")) || {}
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_error(state, message) {
      state.status = "error";
      state.message = message;
      setTimeout(() => {
        state.status = "";
        state.message = "";
      }, 2000);
    },
    auth_success(state, token) {
      state.status = "success";
      state.token = token;
    },
    save_user(state, user) {
      state.user = user;
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
    validation_error(state, message) {
      state.status = "error";
      state.message = message.message;
      setTimeout(() => {
        state.status = "";
        state.message = "";
      }, 2000);
    },
    mobile_verification(state, token) {
      state.token = token;
    },
    first_name_lang_validation(state, message) {
      state.status = "first_name";
      state.message = message.message;
    },
    first_name_lang_is_persian(state) {
      state.status = "true";
    },
    last_name_lang_validation(state, message) {
      state.status = "last_name";
      state.message = message.message;
    },
    last_name_lang_is_persian(state) {
      state.status = "true";
    },
    email_validation(state, message) {
      state.status = "email";
      state.message = message.message;
    },
    user_register(state, user, token) {
      state.user = user;
      state.token = token;
    },
    user_login(state, user, token) {
      state.user = user;
      state.token = token;
    }
  },
  actions: {
    validationError({ commit }, status, error) {
      commit("validation_error", status, error);
    },
    firstNameLangValidation({ commit }, status, error) {
      commit("first_name_lang_validation", status, error);
    },
    firstNameLangIsPersian({ commit }, status) {
      commit("first_name_lang_is_persian", status);
    },
    lastNameLangValidation({ commit }, status, error) {
      commit("last_name_lang_validation", status, error);
    },
    lastNameLangIsPersian({ commit }, status) {
      commit("last_name_lang_is_persian", status);
    },
    emailValidation({ commit }, status, error) {
      commit("email_validation", status, error);
    },
    mobileVerification({ commit }, mobile) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: "auth/mobile-verification",
          data: mobile,
          method: "POST"
        })
          .then(response => {
            if (!response.data.status) {
              commit("auth_error", response.data.message);
              return false;
            }
            const token = response.data.token;
            localStorage.setItem("token", token);
            axios.defaults.headers.common["Authorization"] = "Bearer" + token;
            // commit("mobile_verification", token);
            commit("auth_success", token);
            console.log(response.data);
            resolve(response);
          })
          .catch(error => {
            if (error.response.status === 500) {
              this._vm.$toast.warning(
                "متاسفانه ارتباط با سرور با خطا مواجه شده است!"
              );
              commit("auth_error", "خطایی سروری رخ داده است!");
              return false;
            }
            // console.log(error.response);
            commit("auth_error", error.response);
            localStorage.removeItem("token");
            reject(error);
          });
      });
    },
    codeVerification({ commit, state }, code) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + state.token;
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: "auth/code-verification",
          data: code,
          method: "POST"
        })
          .then(response => {
            if (!response.data.status) {
              commit("auth_error", response.data.message);
              return false;
            }
            this._vm.$toast.success(response.data.message);
            commit("auth_success", state.token);
            resolve(response);
          })
          .catch(error => {
            const message =
              "خطایی رخ داده است. لطفا به مرحله قبل برگردید و دوباره شماره موبایل خود را وارد کنید.";
            if (error.response.status === 401) {
              this._vm.$toast.warning(message);
              commit("auth_error", message);
              return false;
            }
            if (error.response.status === 500) {
              this._vm.$toast.warning(
                "متاسفانه ارتباط با سرور با خطا مواجه شده است!"
              );
              commit("auth_error", "خطایی سروری رخ داده است!");
              return false;
            }
            commit("auth_error", error);
            // localStorage.removeItem("token");
            reject(error);
          });
      });
    },
    register({ commit, state }, user) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + state.token;
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: "/auth/to-register",
          data: user,
          method: "POST"
        })
          .then(response => {
            if (!response.data.status) {
              this._vm.$toast.warning(response.data.message);
              return false;
            }
            const token = response.data.token;
            const user = response.data.user;
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));
            this._vm.$toast.success(response.data.message);
            commit("auth_success", user, token);
            resolve(response);
          })
          .catch(error => {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            console.log(error.response);
            commit("auth_error", error);
            reject(error);
          });
      });
    },
    login({ commit }, data) {
      // console.log(this.$loading.show());
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: "/auth/login",
          data: data,
          method: "POST"
        })
          .then(response => {
            if (!response.data.status) {
              // loader.hide();
              this._vm.$toast.warning(response.data.message);
              commit("auth_error", response.data.message);
              return false;
            }
            this._vm.$toast.success(response.data.message);
            const token = response.data.token;
            const user = response.data.user;
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            commit("auth_success", token, user);
            commit("save_user", user);
            resolve(response);
          })
          .catch(error => {
            console.log(error.response);
            reject(error);
          });
      });
    },
    forgetPasswordMobilVerification({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        axios({
          url: "/auth/password-recovery-mobile-verification",
          data: data,
          method: "POST"
        })
          .then(response => {
            if (!response.data.status) {
              this._vm.$toast.warning(response.data.message);
              commit("auth_error", response.data.message);
              return false;
            }
            const token = response.data.token;
            localStorage.setItem("token", token);
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            commit("auth_success", token);
            console.log(response.data);
            resolve(response);
          })
          .catch(error => {
            if (error.response.status === 500) {
              this._vm.$toast.warning(
                "متاسفانه ارتباط با سرور با خطا مواجه شده است!"
              );
              commit("auth_error", "خطایی سروری رخ داده است!");
              return false;
            }
            reject(error);
          });
      });
    },
    forgetPasswordCodeVerification({ commit, state }, code) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + state.token;
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: "/auth/password-recovery-code-verification",
          data: code,
          method: "POST"
        })
          .then(response => {
            if (!response.data.status) {
              this._vm.$toast.warning(response.data.message);
              commit("auth_error", response.data.message);
              return false;
            }
            this._vm.$toast.success(response.data.message);
            commit("auth_success", state.token);
            resolve(response);
          })
          .catch(error => {
            const message =
              "خطایی رخ داده است. لطفا به مرحله قبل برگردید و دوباره شماره موبایل خود را وارد کنید.";
            if (error.response.status === 401) {
              this._vm.$toast.warning(message);
              commit("auth_error", message);
              return false;
            }
            if (error.response.status === 500) {
              this._vm.$toast.warning(
                "متاسفانه ارتباط با سرور با خطا مواجه شده است!"
              );
              commit("auth_error", "خطایی سروری رخ داده است!");
              return false;
            }
            reject(error);
          });
      });
    },
    forgetPasswordRecovery({ commit, state }, data) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + state.token;
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: "/auth/password-recovery-add-new-password",
          data: data,
          method: "POST"
        })
          .then(response => {
            if (!response.data.status) {
              this._vm.$toast.warning(response.data.message);
              return false;
            }
            const token = response.data.token;
            const user = response.data.user;
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));
            this._vm.$toast.success(response.data.message);
            console.log(response.data);
            commit("auth_success", user, token);
            resolve(response);
          })
          .catch(error => {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            console.log(error.response);
            commit("auth_error", error);
            reject(error);
          });
      });
    },
    logout({ commit }) {
      return new Promise(resolve => {
        commit("logout");
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    }
  },
  modules: {},
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status
  }
});
