<template>
  <div class="mobile-navbar">
    <SidebarMenuIcon />
    <div @click="loginAndRegisterHandler" class="account-wrapper">
      <div class="image-wrapper">
        <img
          src="../../assets/images/mdi_account-circle-outline.png"
          alt=""
          width="30px"
        />
      </div>
    </div>
    <div
      v-if="login_and_register_modal"
      class="login-and-register-wrapper"
      v-click-outside="onClickOutside"
    >
      <div
        v-if="!this.$store.getters.isLoggedIn"
        class="login-and-register-container"
      >
        <div @click="goLogin" class="modal-login">ورود</div>
        <div @click="goRegister" class="modal-register">ثبت نام</div>
      </div>
      <div v-if="this.$store.getters.isLoggedIn" class="user-login-wrapper">
        <div class="user-login-item" @click="goAccount">
          <img
            src="../../assets/images/mdi_account-circle-outline.png"
            alt=""
            width="20px"
          />
          حساب کاربری
        </div>
        <div class="user-login-item" @click="goOrders">
          <img src="../../assets/images/orders.svg" alt="" width="20px" />
          سفارشات
        </div>
        <div class="user-login-item" @click="logout">
          <img
            src="../../assets/images/eva_log-out-fill.svg"
            alt=""
            width="20px"
          />
          خروج
        </div>
      </div>
    </div>
    <nav class="menu-wrapper">
      <ul class="menu-item-wrapper">
        <li @click="goHome">خانه</li>
        <li class="dropdown-item1">
          <div class="span-wrapper">
            <span>خدمات ما</span>
            <span>
              <img src="../../assets/images/navbar-dropdown-item.svg" alt="" />
            </span>
          </div>
          <ul class="dropdown-wrapper1">
            <li @click="goDigitalCurrencyTransactions">معاملات رمز ارزها</li>
            <li class="dropdown-item2">
              <div class="span-wrapper">
                <span>خدمات ووچر</span>
                <span>
                  <img
                    src="../../assets/images/navbar-dropdown-item.svg"
                    alt=""
                  />
                </span>
              </div>
              <ul class="dropdown-wrapper2">
                <li @click="goPerfectMoneyVoucher">ووچر پرفکت مانی</li>
                <li @click="goHotVoucher">هات ووچر</li>
                <li @click="goPsVoucher">پی اس ووچر</li>
              </ul>
            </li>
            <li @click="goPayPalService">خدمات پرفکت مانی</li>
            <li @click="goWebMoneyService">خدمات وب مانی</li>
            <li @click="goPayPalService">خدمات پی پل</li>
            <li @click="goSkrillService">خدمات اسکریل</li>
          </ul>
        </li>
        <li @click="goTermsAndConditions">
          قوانین و مقررات
        </li>
        <li @click="goBlog">
          بلاگ
        </li>
        <li @click="goFAQ">
          سوالات متداول
        </li>
        <li @click="goAboutUs">
          درباره ما
        </li>
        <li @click="goContactUs">
          تماس با ما
        </li>
      </ul>
    </nav>
    <NavbarMenuIcon />
  </div>
</template>

<script>
import NavbarMenuIcon from "@/components/NavbarMenuIcon";
import SidebarMenuIcon from "@/components/SidebarMenuIcon";
import vClickOutside from "v-click-outside";

export default {
  name: "MobileNavbar",
  components: { SidebarMenuIcon, NavbarMenuIcon },
  data() {
    return {
      login_and_register_modal: false,
      is_menu_expanded: false
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    toggleShow() {
      const sideBarNavBar = document.querySelector(".menu-wrapper");
      sideBarNavBar.classList.toggle("show");
      const menuBox = document.querySelector(".menu-box");
      if (menuBox.classList.contains("active")) {
        menuBox.classList.remove("active");
        menuBox.classList.add("close");
        setTimeout(() => {
          menuBox.classList.remove("close");
        }, 1000);
      }
    },
    onClickOutside() {
      this.login_and_register_modal = !this.login_and_register_modal;
    },
    goHome() {
      this.$router.push("/");
      this.toggleShow();
    },
    goDigitalCurrencyTransactions() {
      this.$router.push("/digital-currency-equations");
      this.toggleShow();
    },
    goPerfectMoneyVoucher() {
      this.$router.push("/voucher-services");
      this.toggleShow();
    },
    goHotVoucher() {
      this.$router.push("/hot-voucher");
      this.toggleShow();
    },
    goPsVoucher() {
      this.$router.push("/ps-voucher");
      this.toggleShow();
    },
    goPerfectMoneyService() {
      this.$router.push("/perfect-money-service");
      this.toggleShow();
    },
    goWebMoneyService() {
      this.$router.push("/web-money-service");
      this.toggleShow();
    },
    goPayPalService() {
      this.$router.push("/paypal-service");
      this.toggleShow();
    },
    goSkrillService() {
      this.$router.push("/skrill-service");
      this.toggleShow();
    },
    goTermsAndConditions() {
      this.$router.push("/privacy-policy");
      this.toggleShow();
    },
    goBlog() {
      this.$router.push("/blog");
      this.toggleShow();
    },
    goFAQ() {
      this.$router.push("/faq");
      this.toggleShow();
    },
    goAboutUs() {
      this.$router.push("/about-us");
      this.toggleShow();
    },
    goContactUs() {
      this.$router.push("/contact-us");
      this.toggleShow();
    },
    goLogin() {
      this.$router.push("/login");
      this.login_and_register_modal = !this.login_and_register_modal;
    },
    goRegister() {
      this.$router.push("/register");
      this.login_and_register_modal = !this.login_and_register_modal;
    },
    loginAndRegisterHandler() {
      this.login_and_register_modal = !this.login_and_register_modal;
    },
    goAccount() {
      this.login_and_register_modal = !this.login_and_register_modal;
      this.$router.push("/dashboard");
    },
    goOrders() {
      this.login_and_register_modal = !this.login_and_register_modal;
      this.$router.push("/orders");
    },
    logout() {
      this.login_and_register_modal = !this.login_and_register_modal;
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    }
  }
};
</script>

<style scoped>
.mobile-navbar {
  height: 50px;
  background-color: #070d59;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

nav {
  width: 100%;
  max-width: 250px;
  position: fixed;
  height: 100vh;
  background-color: rgba(7, 13, 89, 0.9);
  top: 50px;
  left: 0;
  display: flex;
  justify-content: center;
  transform: translateX(-100%);
  transition: transform 0.4s linear;
  /*align-items: center;*/
}

ul {
  width: 100%;
}

li {
  color: #ffffff;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  padding: 15px 25px;
  list-style: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.menu-item-wrapper {
  display: flex;
  flex-direction: column;
}

.span-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.dropdown-wrapper1 {
  display: none;
}

.dropdown-wrapper2 {
  display: none;
}

.dropdown-item1:hover .dropdown-wrapper1 {
  display: flex;
  flex-direction: column;
}

.dropdown-item2:hover .dropdown-wrapper2 {
  display: flex;
  flex-direction: column;
}

.image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-and-register-wrapper {
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 100px;
  background-color: #070d59;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
}

.login-and-register-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.modal-login {
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
}

.modal-register {
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
}

.user-login-wrapper {
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  gap: 10px;
}

.user-login-item {
  color: #ffffff;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
/*.close {*/
/*  transform: translateX(100%);*/
/*  !*left: -100%;*!*/
/*}*/

.menu-wrapper.show {
  transform: translateX(0) !important;
}
</style>
