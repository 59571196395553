<template>
  <div id="navbar">
    <NavbarMenu class="desktop-navbar" />
    <MobileNavbar class="mobile-navbar" />
  </div>
</template>
<script>
import NavbarMenu from "@/components/TopNavBar/NavbarMenu";
import MobileNavbar from "@/components/TopNavBar/MobileNavbar";

export default {
  components: { MobileNavbar, NavbarMenu }
};
</script>
<style scoped>
/*a {*/
/*  color: #fff;*/
/*}*/

#navbar {
  position: sticky;
  z-index: 999999;
  top: 0;
}

/*#nav-container {*/
/*  margin: auto;*/
/*  display: flex;*/
/*  width: 100%;*/
/*  justify-content: space-between;*/
/*  align-items: center;*/
/*  height: 50px;*/
/*  color: #fff;*/
/*  padding: 0 10px;*/

/*}*/

/*.dashboard-icon {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  cursor: pointer;*/
/*}*/

/*.logo {*/
/*  height: 30px;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  display: none;*/
/*}*/

/*li {*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*  font-size: 14px;*/
/*  line-height: 22px;*/
/*}*/

/*ul.nav-links {*/
/*  position: fixed;*/
/*  left: 0;*/
/*  top: 50px;*/
/*  background: #070D59;*/
/*  height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  width: 100%;*/
/*  padding: 50px 10px;*/
/*  transform: translateX(-100%);*/
/*  transition: transform 0.4s linear;*/
/*  !*gap: 50px;*!*/
/*}*/

/*ul.nav-links li {*/
/*  list-style: none;*/
/*  padding-left: 25px;*/
/*  padding-right: 25px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/

/*li.dropdown1 {*/
/*  position: relative;*/
/*  height: 50px;*/
/*}*/

/*li.dropdown2 {*/
/*  position: relative;*/
/*  height: 50px;*/
/*}*/

/*ul.nav-contents {*/
/*  display: none;*/
/*}*/

/*ul.nav-contents2 {*/
/*  display: none;*/
/*}*/

/*li.dropdown1:hover ul.nav-contents {*/
/*  display: flex;*/
/*  transition: 0.5s ease;*/
/*}*/

/*li.dropdown2:hover ul.nav-contents2 {*/
/*  display: flex;*/
/*  transition: 0.5s ease;*/
/*}*/

/*.divider {*/
/*  top: 50px;*/
/*  right: 0;*/
/*  position: fixed;*/
/*  width: 100vw;*/
/*  height: 0;*/
/*  background-color: var(--blue);*/
/*  z-index: -1;*/
/*}*/

/*a {*/
/*  color: #fff;*/
/*}*/

/*.nav-contents {*/
/*  position: absolute;*/
/*  right: 0;*/
/*  top: 100%;*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  width: max-content;*/
/*  height: 50px;*/
/*  align-items: center;*/
/*  justify-content: space-between;*/
/*}*/

/*.nav-contents2 {*/
/*  position: absolute;*/
/*  right: 0;*/
/*  top: 100%;*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  width: max-content;*/
/*  height: 50px;*/
/*  align-items: center;*/
/*  justify-content: space-between;*/
/*}*/

/*.singup {*/
/*  margin-right: 12px;*/
/*  margin-left: 3px;*/
/*  cursor: pointer;*/
/*}*/

/*.register {*/
/*  margin-right: 5px;*/
/*  margin-left: 5px;*/
/*  cursor: pointer;*/
/*}*/

/*ul.nav-contents li {*/
/*  list-style: none;*/
/*  padding-left: 25px;*/
/*  padding-right: 25px;*/
/*  height: 50px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  !*opacity: 0;*!*/
/*}*/

/*ul.nav-contents2 li {*/
/*  list-style: none;*/
/*  padding-left: 25px;*/
/*  padding-right: 25px;*/
/*  height: 50px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/

/*.divider1 {*/
/*  top: 50px;*/
/*  right: 0;*/
/*  position: fixed;*/
/*  width: 100vw;*/
/*  height: 0;*/
/*  background-color: var(--blue);*/
/*  z-index: -1;*/
/*  display: none;*/
/*}*/

/*.divider2 {*/
/*  top: 50px;*/
/*  right: 0;*/
/*  position: fixed;*/
/*  width: 100vw;*/
/*  height: 0;*/
/*  background-color: var(--blue);*/
/*  z-index: -1;*/
/*  display: none;*/
/*}*/

/*li.dropdown1:hover .divider1 {*/
/*  transition: 0.5s ease;*/
/*  height: 50px;*/
/*  border-top: 0.25px solid #7e7d7d;*/
/*  border-bottom: 0.25px solid #7e7d7d;*/
/*}*/

/*.dropdown2:hover .divider2 {*/
/*  transition: 0.5s ease;*/
/*  height: 100px;*/
/*}*/

/*.profile {*/
/*  width: max-content;*/
/*  !*display: flex;*!*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  display: none;*/
/*}*/

/*.vector {*/
/*  margin-right: 8px;*/
/*}*/

/*#nav-icon {*/
/*  font-size: 30px;*/
/*  padding: 10px 10px 20px;*/
/*  cursor: pointer;*/
/*}*/

/*.social {*/
/*  width: 40px;*/
/*}*/

/*.singup {*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*  font-size: 14px;*/
/*  line-height: 22px;*/
/*}*/

/*.register {*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*  font-size: 14px;*/
/*  line-height: 22px;*/
/*}*/

/*#navbar {*/
/*  width: 100%;*/
/*  background: #070d59;*/
/*  !*padding: 0 10px;*!*/
/*  height: 50px;*/
/*  position: fixed;*/
/*  top: 0;*/
/*  left: 0;*/
/*  z-index: 1000;*/
/*}*/

/*#nav-container {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: space-between;*/
/*  height: 50px;*/
/*  position: relative;*/
/*}*/

/*.background-dropdown:after {*/
/*  position: absolute;*/
/*  content: "";*/
/*  height: 50px;*/
/*  width: 100%;*/
/*  background-color: #070d59;*/
/*  top: 100%;*/
/*  right: 0;*/
/*  border-top: 1px solid #f3f3f3;*/
/*}*/

/*.background-dropdown2:after {*/
/*  position: absolute;*/
/*  content: "";*/
/*  height: 50px;*/
/*  width: 100%;*/
/*  background-color: #070d59;*/
/*  top: 100px;*/
/*  right: 0;*/
/*  border-top: 1px solid #f3f3f3;*/
/*}*/

/*.responsive-icon-wrapper {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: space-between;*/
/*  width: 100%;*/
/*  height: 50px;*/
/*}*/

/*.logo {*/
/*  display: none;*/
/*}*/

/*.profile {*/
/*  !*display: none;*!*/
/*}*/

/*.nav-links {*/
/*  position: fixed;*/
/*  left: 0;*/
/*  top: 50px;*/
/*  background: #070d59;*/
/*  height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  width: 100%;*/
/*  padding: 10px 0;*/
/*  transform: translateX(-100%);*/
/*  transition: transform 0.4s linear;*/
/*  z-index: 9999;*/
/*  text-align: center;*/
/*  !*justify-content: center;*!*/
/*  !*align-items: center;*!*/
/*  gap: 10px;*/
/*  !*  !*gap: 50px;*!*!*/
/*}*/

/*ul {*/
/*  list-style: none;*/
/*  padding: 10px 0;*/
/*  width: 100%;*/
/*}*/

/*.nav-contents {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  flex-direction: column;*/
/*  gap: 10px;*/
/*  background-color: rgba(18, 18, 18, 0.3);*/
/*}*/

/*.nav-contents2 {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  flex-direction: column;*/
/*  gap: 10px;*/
/*  background-color: rgba(18, 18, 18, 0.3);*/
/*}*/

/*li {*/
/*  width: 100%;*/
/*  padding: 5px 5px;*/
/*  font-size: 14px;*/
/*  !*border-bottom: 1px solid #121212;*!*/
/*}*/

/*.vector {*/
/*  margin-right: 10px;*/
/*}*/

/*.profile {*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/

/*.social {*/
/*  display: none;*/
/*}*/

/*.singup {*/
/*  display: none;*/
/*}*/

/*.singupMobile {*/
/*  color: #ffffff;*/
/*  width: 100%;*/
/*  text-align: center;*/
/*  padding: 10px 0;*/
/*}*/

/*.registerMobile {*/
/*  color: #ffffff;*/
/*  width: 100%;*/
/*  text-align: center;*/
/*  padding: 10px 0;*/
/*}*/

/*.register {*/
/*  display: none;*/
/*}*/

/*.slash {*/
/*  display: none;*/
/*}*/

/*.mobile-profile {*/
/*  width: 150px;*/
/*  height: 100px;*/
/*  position: fixed;*/
/*  top: 50px;*/
/*  left: 50%;*/
/*  background: #070d59;*/
/*  transform: translateX(-50%);*/
/*  border-bottom-left-radius: 5px;*/
/*  border-bottom-right-radius: 5px;*/
/*}*/

.show {
  transform: translateX(0) !important;
}

/*@media (min-width: 720px) {*/
/*  .nav-links {*/
/*    width: 320px;*/
/*  }*/
/*}*/

/*@media (min-width: 992px) {*/
/*  #navbar {*/
/*    position: relative;*/
/*  }*/

/*  #nav-container {*/
/*    padding: 0 70px;*/
/*    gap: 50px;*/
/*  }*/

/*  .nav-contents {*/
/*    position: absolute;*/
/*    top: 50px;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    background-color: transparent;*/
/*    flex-direction: row !important;*/
/*  }*/

/*  .nav-contents2 {*/
/*    position: absolute;*/
/*    top: 50px;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    background-color: #070d59;*/
/*    flex-direction: row !important;*/
/*  }*/

/*  .sidebar-menu-icon {*/
/*    display: none;*/
/*  }*/

/*  .navbar-menu-icon {*/
/*    display: none;*/
/*  }*/

/*  .logo {*/
/*    display: block;*/
/*  }*/

/*  .profile {*/
/*    display: flex;*/
/*    width: 30% !important;*/
/*  }*/

/*  .nav-links {*/
/*    display: flex;*/
/*    flex-direction: row !important;*/
/*    position: initial !important;*/
/*    justify-content: flex-start;*/
/*    width: calc(100% - 300px);*/
/*    padding: 0 !important;*/
/*    height: 100% !important;*/
/*    transform: initial !important;*/
/*    transition: initial !important;*/
/*    align-items: center !important;*/
/*    !*gap: 50px !important;*!*/
/*  }*/

/*  .nav-links {*/
/*  }*/

/*  li {*/
/*    padding: 0 25px;*/
/*    width: unset;*/
/*  }*/

/*  #nav-container {*/
/*    gap: 25px;*/
/*  }*/

/*  .social {*/
/*    display: block;*/
/*    cursor: pointer;*/
/*    !*width: 40px;*!*/
/*  }*/

/*  .singup {*/
/*    display: block;*/
/*    cursor: pointer;*/
/*  }*/

/*  .profile {*/
/*    display: flex;*/
/*    color: #ffffff;*/
/*    font-size: 14px;*/
/*    gap: 5px;*/
/*    align-items: center;*/
/*    justify-content: flex-end;*/
/*  }*/

/*  .register {*/
/*    display: block;*/
/*  }*/

/*  .slash {*/
/*    display: block;*/
/*  }*/

/*  .social-wrapper {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    margin-left: 10px;*/
/*  }*/

/*#navbar {*/
/*  width: 100%;*/
/*  background-color: #070d59;*/
/*  position: sticky;*/
/*  z-index: 999999;*/
/*  top: 0;*/
/*}*/

/*#nav-container {*/
/*  margin: auto;*/
/*  display: flex;*/
/*  width: 100%;*/
/*  justify-content: space-between;*/
/*  align-items: center;*/
/*  height: 50px;*/
/*  color: #fff;*/
/*  padding: 0 70px;*/
/*}*/

/*.dashboard-icon {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  cursor: pointer;*/
/*}*/

/*.logo {*/
/*  height: 30px;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  !*display: none;*!*/
/*}*/

/*li {*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*  font-size: 14px;*/
/*  line-height: 22px;*/
/*}*/

/*ul.nav-links {*/
/*  position: fixed;*/
/*  left: 0;*/
/*  top: 50px;*/
/*  background: #070d59;*/
/*  height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  width: 100%;*/
/*  padding: 50px 10px;*/
/*  transform: translateX(-100%);*/
/*  transition: transform 0.4s linear;*/
/*  !*gap: 50px;*!*/
/*}*/

/*ul.nav-links li {*/
/*  list-style: none;*/
/*  padding-left: 5px !important;*/
/*  padding-right: 5px !important;*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/

/*li.dropdown1 {*/
/*  position: relative;*/
/*  height: 50px;*/
/*}*/

/*li.dropdown2 {*/
/*  position: relative;*/
/*  height: 50px;*/
/*}*/

/*ul.nav-contents {*/
/*  display: none;*/
/*}*/

/*ul.nav-contents2 {*/
/*  display: none;*/
/*}*/

/*li.dropdown1:hover ul.nav-contents {*/
/*  display: flex;*/
/*  transition: 0.5s ease;*/
/*}*/

/*li.dropdown2:hover ul.nav-contents2 {*/
/*  display: flex;*/
/*  transition: 0.5s ease;*/
/*}*/

/*.divider {*/
/*  top: 50px;*/
/*  right: 0;*/
/*  position: fixed;*/
/*  width: 100vw;*/
/*  height: 0;*/
/*  background-color: var(--blue);*/
/*  z-index: -1;*/
/*}*/

/*a {*/
/*  color: #fff;*/
/*}*/

/*.nav-contents {*/
/*  position: absolute;*/
/*  right: 0;*/
/*  top: 100%;*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  width: max-content;*/
/*  height: 50px;*/
/*  align-items: center;*/
/*  justify-content: space-between;*/
/*}*/

/*.nav-contents2 {*/
/*  position: absolute;*/
/*  right: 0;*/
/*  top: 100%;*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  width: max-content;*/
/*  height: 50px;*/
/*  align-items: center;*/
/*  justify-content: space-between;*/
/*}*/

/*.singup {*/
/*  margin-right: 12px;*/
/*  margin-left: 3px;*/
/*  cursor: pointer;*/
/*}*/

/*.register {*/
/*  margin-right: 5px;*/
/*  margin-left: 5px;*/
/*  cursor: pointer;*/
/*  width: 50px;*/
/*}*/

/*ul.nav-contents li {*/
/*  list-style: none;*/
/*  padding-left: 25px;*/
/*  padding-right: 25px;*/
/*  height: 50px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  !*opacity: 0;*!*/
/*}*/

/*ul.nav-contents2 li {*/
/*  list-style: none;*/
/*  padding-left: 25px;*/
/*  padding-right: 25px;*/
/*  height: 50px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/

/*.divider1 {*/
/*  top: 50px;*/
/*  right: 0;*/
/*  position: fixed;*/
/*  width: 100vw;*/
/*  height: 0;*/
/*  background-color: var(--blue);*/
/*  z-index: -1;*/
/*  display: none;*/
/*}*/

/*.divider2 {*/
/*  top: 50px;*/
/*  right: 0;*/
/*  position: fixed;*/
/*  width: 100vw;*/
/*  height: 0;*/
/*  background-color: var(--blue);*/
/*  z-index: -1;*/
/*  display: none;*/
/*}*/

/*li.dropdown1:hover .divider1 {*/
/*  transition: 0.5s ease;*/
/*  height: 50px;*/
/*  border-top: 0.25px solid #7e7d7d;*/
/*  border-bottom: 0.25px solid #7e7d7d;*/
/*}*/

/*.dropdown2:hover .divider2 {*/
/*  transition: 0.5s ease;*/
/*  height: 100px;*/
/*}*/

/*.profile {*/
/*  width: max-content;*/
/*  !*display: flex;*!*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  display: none;*/
/*}*/

/*.vector {*/
/*  margin-right: 8px;*/
/*}*/

/*#nav-icon {*/
/*  font-size: 30px;*/
/*  padding: 10px 10px 20px;*/
/*  cursor: pointer;*/
/*}*/

/*.social {*/
/*  width: 40px;*/
/*}*/

/*.singup {*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*  font-size: 14px;*/
/*  line-height: 22px;*/
/*}*/

/*.register {*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*  font-size: 14px;*/
/*  line-height: 22px;*/
/*}*/

/*@media (min-width: 1200px) {*/
/*  ul.nav-links li {*/
/*    padding-left: 25px !important;*/
/*    padding-right: 25px !important;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 768px) {*/
/*  .login-card {*/
/*    display: none;*/
/*  }*/
/*}*/

.desktop-navbar {
  display: none;
}

.mobile-navbar {
  display: none;
}

@media (min-width: 992px) {
  .desktop-navbar {
    display: flex;
  }
}

@media (max-width: 992px) {
  .mobile-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>

<!--components: {SidebarMenuIcon, NavbarMenuIcon},-->
