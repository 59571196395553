import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/test",
    name: "Test",
    component: () => import("../views/Test.vue")
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      breadcrumb: [{ name: "خانه" }]
    }
  },
  {
    path: "/panel",
    name: "Panel",
    component: () => import("../views/Panel.vue"),
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("../views/Dashboard.vue")
      },
      {
        path: "/account-authentication",
        name: "AccountAuthentication",
        component: () => import("../views/AccountAuthentication")
      },
      {
        path: "/identify-two-factors",
        name: "IdentifyTwoFactors",
        component: () => import("../views/IdentifyTwoFactors")
      },
      {
        path: "/change-password",
        name: "ChangePassword",
        component: () => import("../views/ChangePassword")
      },
      {
        path: "/wallet",
        name: "Wallet",
        component: () => import("../views/Wallet")
      },
      {
        path: "/bank-cards",
        name: "BankCards",
        component: () => import("../views/BankCards")
      },
      {
        path: "/transaction",
        name: "Transaction",
        component: () => import("../views/Transaction")
      },
      {
        path: "/earn-money",
        name: "EarnMoney",
        component: () => import("../views/EarnMoney")
      },
      {
        path: "/digital-currency-equations",
        name: "DigitalCurrencyEquations",
        component: () => import("../views/DigitalCurrencyEquations")
      },
      {
        path: "/voucher-services",
        name: "VoucherServices",
        component: () => import("../views/PerfectMoneyVoucher")
      },
      {
        path: "/hot-voucher",
        name: "HotVoucher",
        component: () => import("../views/HotVoucher")
      },
      {
        path: "/ps-voucher",
        name: "PsVoucher",
        component: () => import("../views/PsVoucher")
      },
      {
        path: "/perfect-money-service",
        name: "PerfectMoneyService",
        component: () => import("../views/PerfectMoneyService")
      },
      {
        path: "/web-money-service",
        name: "webMoneyService",
        component: () => import("../views/WebMoneyService")
      },
      {
        path: "/paypal-service",
        name: "PayPalService",
        component: () => import("../views/PayPalService")
      },
      {
        path: "/skrill-service",
        name: "SkrillService",
        component: () => import("../views/SkrillService")
      },
      {
        path: "/orders",
        name: "Orders",
        component: () => import("../views/Orders")
      },
      {
        path: "/support",
        name: "Support",
        component: () => import("../views/Support")
      }
    ]
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: () => import("../views/ContactUs.vue"),
    meta: {
      breadcrumb: [{ name: "تماس با ما" }]
    }
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: () => import("../views/AboutUs.vue"),
    meta: {
      breadcrumb: [{ name: "درباره ما" }]
    }
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () => import("../views/Faq.vue"),
    meta: {
      breadcrumb: [{ name: "سوالات متداول" }]
    }
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
    meta: {
      breadcrumb: [{ name: "قوانین و مقررات" }]
    }
  },

  // Auth router
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Auth/Login.vue")
  },
  {
    path: "/code-verify",
    name: "CodeVerify",

    component: () => import("../views/Auth/CodeVerify.vue")
  },
  {
    path: "/register",
    name: "Register",

    component: () => import("../views/Auth/Register.vue")
  },
  {
    path: "/register-code-verify",
    name: "RegisterCodeVerify",

    component: () => import("../views/Auth/RegisterCodeVerify.vue")
  },
  {
    path: "/register-information",
    name: "RegisterInformation",

    component: () => import("../views/Auth/RegisterInformations.vue")
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",

    component: () => import("../views/Auth/ForgetPassword.vue")
  },
  {
    path: "/forget-password-code-verify",
    name: "ForgetPasswordCodeVerify",

    component: () => import("../views/Auth/ForgetPasswordCodeVerify.vue")
  },
  {
    path: "/recover-password",
    name: "RecoverPassword",

    component: () => import("../views/Auth/RecoverPassword")
  },
  // end Auth router
  {
    path: "/blog",
    name: "Blog",
    component: () => import("../views/Blog")
  },
  {
    path: "/article/:id",
    name: "Article",
    component: () => import("../views/ArticlePage")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
