import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueApexCharts from "vue-apexcharts";
import Carousel3d from "vue-carousel-3d";
import vClickOutside from "v-click-outside";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import VueSimpleAlert from "vue-simple-alert";
// import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import Toast, { POSITION } from "vue-toastification";
//axios
import axios from "axios";

import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);

Vue.prototype.$http = axios;
// Vue.prototype.$http.defaults.baseURL = "http://127.0.0.1:8000/api/";
Vue.prototype.$http.defaults.baseURL =
  "http://exchangeaz-api.9.vistadev.ir/api";

const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] =
    "Bearer " + token;
}

import "@/assets/fonts/stylesheet.css";

const options = {
  position: POSITION.BOTTOM_LEFT,
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: true,
  toastClassName: "my-custom-toast-class"
};

Vue.use(VueLoading, {
  loader: "bars",
  color: "#080F66"
});

Vue.use(Carousel3d);
Vue.use(VueApexCharts);
Vue.use(vClickOutside);
Vue.use(Toast, options);
Vue.use(VueSimpleAlert);
Vue.use(require("vue-jalali-moment"));
Vue.component("apexchart", VueApexCharts);
Vue.component("date-picker", VuePersianDatetimePicker);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
