<template>
  <div class="sidebar-menu-box" ref="sidebar-menu-box" @click="toggleActive">
    <span class="sidebar-menu"></span>
  </div>
</template>

<script>
export default {
  methods: {
    toggleActive() {
      const sidebarMenuBox = this.$refs["sidebar-menu-box"];
      const sideBar = document.querySelector("#sidebar");
      // const content = document.querySelector(
      //   "#digitalCurrencyEquations"
      // );
      // console.log(content);
      sideBar.classList.toggle("show");
      // content.classList.toggle("show");
      // const sideBarNavBarMenu = document.querySelector(".sidebar-navbar-menu");
      //const menuBoxIcon = document.querySelector('.menu-box');
      // if (sideBarNavBarMenu.classList.contains('show')){
      //   sideBarNavBarMenu.classList.remove('show');
      //   menuBoxIcon.classList.remove('active');
      //   menuBoxIcon.classList.add('close');
      //   setTimeout(()=>{
      //     menuBoxIcon.classList.remove('close');
      //   },1000)
      // }

      // if (sideBar && content){
      //   sideBar.classList.toggle('show');
      //   content.classList.toggle('show');
      // }
      sidebarMenuBox.classList.toggle("active");
      if (sidebarMenuBox.classList.contains("active")) {
        sidebarMenuBox.classList.remove("close");
      } else {
        sidebarMenuBox.classList.add("close");
        setTimeout(() => {
          sidebarMenuBox.classList.remove("close");
        }, 1000);
      }
    }
  }
};
</script>

<style scoped>
.sidebar-menu-box {
  position: relative;
  width: 40px;
  height: 40px;
  margin-left: 15px;
  cursor: pointer;
}

.sidebar-menu::before,
.sidebar-menu::after {
  position: absolute;
  content: "";
  height: 3px;
  border-radius: 5px;
  width: 70%;
  background-color: #fff;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: center center;
}

.sidebar-menu::before {
  top: 25%;
  box-shadow: 0 10px 0 0 #fff;
}

.sidebar-menu::after {
  top: 75%;
}

.sidebar-menu-box.active .sidebar-menu::after {
  animation: positioningBottom 0.2s 0.2s linear forwards,
    rotateAfter 0.2s 0.4s linear forwards;
}

.sidebar-menu-box.active .sidebar-menu::before {
  animation: hideShadows 0.2s linear forwards,
    positioning 0.2s 0.2s linear forwards,
    rotateBefore 0.2s 0.4s linear forwards;
}

.sidebar-menu-box.close .sidebar-menu::after {
  animation: rotateSidebarCloseAfter 0.2s linear forwards,
    positioningCloseBottom 0.2s 0.2s linear forwards;
}

.sidebar-menu-box.close .sidebar-menu::before {
  animation: rotateSidebarCloseBefore 0.2s linear forwards,
    closePositioning 0.2s 0.2s linear forwards,
    showShadows 0.2s 0.4s linear forwards;
}
</style>

<style>
/* Open Animations */
@keyframes hideShadows {
  from {
    box-shadow: 0 10px 0 0 #fff;
  }
  to {
    box-shadow: 0 10px 0 0 transparent;
  }
}

@keyframes positioning {
  from {
    top: 25%;
  }
  to {
    top: 50%;
  }
}

@keyframes positioningBottom {
  from {
    top: 75%;
  }
  to {
    top: 50%;
  }
}

@keyframes rotateAfter {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

@keyframes rotateBefore {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

/* Close Animations */
@keyframes rotateSidebarCloseAfter {
  from {
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  to {
    top: 50%;
    transform: translate(-50%, 0) rotate(0);
  }
}

@keyframes rotateSidebarCloseBefore {
  from {
    box-shadow: 0 10px 0 0 transparent;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  to {
    box-shadow: 0 10px 0 0 transparent;
    top: 50%;
    transform: translate(-50%, 0) rotate(0);
  }
}

@keyframes positioningCloseBottom {
  from {
    top: 50%;
  }
  to {
    top: 75%;
  }
}

@keyframes closePositioning {
  from {
    top: 50%;
  }
  to {
    top: 25%;
  }
}

@keyframes showShadows {
  from {
    box-shadow: 0 10px 0 0 transparent;
  }
  to {
    box-shadow: 0 10px 0 0 #fff;
  }
}
</style>
