<template>
  <nav>
    <div class="logo">
      <img src="../../assets/images/icon_app.png" alt="logo" width="100%" />
    </div>
    <ul class="menu-item-wrapper">
      <li @click="goHome"><span>خانه</span></li>
      <li class="dropdown-item">
        <div class="span-wrapper">
          <span>خدمات ما</span>
          <span>
            <img src="../../assets/images/navbar-dropdown-item.svg" alt="" />
          </span>
        </div>
        <ul class="dropdown-wrapper1">
          <li @click="goDigitalCurrencyTransactions">معاملات رمز ارزها</li>
          <li class="dropdown-item2">
            <div class="span-wrapper">
              <span>خدمات ووچر</span>
              <span>
                <img
                  src="../../assets/images/navbar-dropdown-item.svg"
                  alt=""
                />
              </span>
            </div>
            <ul class="dropdown-wrapper2">
              <li @click="goPerfectMoneyVoucher">ووچر پرفکت مانی</li>
              <li @click="goHotVoucher">هات ووچر</li>
              <li @click="goPsVoucher">پی اس ووچر</li>
            </ul>
          </li>
          <li @click="goPerfectMoneyService">خدمات پرفکت مانی</li>
          <li @click="goWebMoneyService">خدمات وب مانی</li>
          <li @click="goPayPalService">خدمات پی پل</li>
          <li @click="goSkrillService">خدمات اسکریل</li>
        </ul>
      </li>
      <li @click="goTermsAndConditions">قوانین و مقررات</li>
      <li @click="goBlog">بلاگ</li>
      <li @click="goFAQ">سوالات متداول</li>
      <li @click="goAboutUs">درباره ما</li>
      <li @click="goContactUs">تماس با ما</li>
    </ul>
    <div class="profile-wrapper">
      <div class="social-wrapper">
        <a href="https://www.whatsapp.com/">
          <img
            src="../../assets/images/watssapp-nav-bar-icon.svg"
            alt=""
            class="social-image"
          />
        </a>
        <a href="https://www.telegram.org/">
          <img src="../../assets/images/telegran-icon-navbar.svg" alt="" />
        </a>
      </div>
      <div class="account-wrapper" v-if="!this.$store.getters.isLoggedIn">
        <img
          src="../../assets/images/mdi_account-circle-outline.png"
          alt=""
          class="account-image"
        />
        <div class="login-register">
          <div @click="goLogin" class="login">ورود</div>
          <div class="slash">/</div>
          <div @click="goRegister" class="register">ثبت نام</div>
        </div>
      </div>
      <div
        class="account-information"
        v-if="this.$store.getters.isLoggedIn"
        v-click-outside="accountInformationDropDownHide"
        @click="accountInformationDropDownShow"
      >
        <img
          src="../../assets/images/mdi_account-circle-outline.png"
          alt=""
          class="account-image"
        />
        {{
          this.$store.state.user.first_name +
            " " +
            this.$store.state.user.last_name
        }}
      </div>
      <div
        class="account-information-drop-down"
        v-if="accountInformationDropDown"
      >
        <div class="account-information-drop-down-item" @click="goAccount">
          <img
            src="../../assets/images/mdi_account-circle-outline.png"
            alt=""
          />
          <div class="">حساب کاربری</div>
        </div>
        <div class="account-information-drop-down-item" @click="goOrders">
          <img src="../../assets/images/orders.svg" alt="" />
          <div class="">سفارشات</div>
        </div>
        <div class="account-information-drop-down-item" @click="logout">
          <img src="../../assets/images/eva_log-out-fill.svg" alt="" />
          <div class="">خروج</div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
  name: "NavbarMenu",
  data() {
    return {
      accountInformationDropDown: false
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
    goDigitalCurrencyTransactions() {
      this.$router.push("/digital-currency-equations");
    },
    goPerfectMoneyVoucher() {
      this.$router.push("/voucher-services");
    },
    goHotVoucher() {
      this.$router.push("/hot-voucher");
    },
    goPsVoucher() {
      this.$router.push("/ps-voucher");
    },
    goPerfectMoneyService() {
      this.$router.push("/perfect-money-service");
    },
    goWebMoneyService() {
      this.$router.push("/web-money-service");
    },
    goPayPalService() {
      this.$router.push("/paypal-service");
    },
    goSkrillService() {
      this.$router.push("/skrill-service");
    },
    goTermsAndConditions() {
      this.$router.push("/privacy-policy");
    },
    goBlog() {
      this.$router.push("/blog");
    },
    goFAQ() {
      this.$router.push("/faq");
    },
    goAboutUs() {
      this.$router.push("/about-us");
    },
    goContactUs() {
      this.$router.push("/contact-us");
    },
    goLogin() {
      this.$router.push("/login");
    },
    goRegister() {
      this.$router.push("/register");
    },
    accountInformationDropDownShow() {
      this.accountInformationDropDown = true;
    },
    accountInformationDropDownHide() {
      this.accountInformationDropDown = false;
    },
    goAccount() {
      this.accountInformationDropDown = !this.accountInformationDropDown;
      this.$router.push("/dashboard");
    },
    goOrders() {
      this.accountInformationDropDown = !this.accountInformationDropDown;
      this.$router.push("/orders");
    },
    logout() {
      this.accountInformationDropDown = !this.accountInformationDropDown;
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    }
  }
};
</script>

<style scoped>
nav {
  display: flex;
  align-items: center;
  padding: 0 70px;
  height: 50px;
  width: 100%;
  background-color: #070d59;
}

a {
  display: flex;
  align-items: center;
}

.logo {
  width: 25px;
  height: 30px;
}

ul {
  display: flex;
}

.menu-item-wrapper {
  margin-right: 25px;
  width: calc(100% - 300px);
}

li {
  list-style: none;
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  padding: 18px 10px;
  cursor: pointer;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.dropdown-item:hover .dropdown-wrapper1 {
  display: flex;
  align-items: center;
}

.dropdown-item2:hover .dropdown-wrapper2 {
  display: flex;
  align-items: center;
}

.dropdown-wrapper1 {
  display: none;
  position: absolute;
  top: 100%;
  background-color: #070d59;
  width: 100%;
  right: 0;
  padding-right: 190px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  max-height: 50px;
}

.dropdown-wrapper2 {
  display: none;
  position: absolute;
  top: 100%;
  background-color: #070d59;
  width: 100%;
  right: 0;
  padding-right: 335px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  max-height: 50px;
}

.profile-wrapper {
  display: flex;
  align-items: center;
}

.social-image {
  width: 25px;
}

.social-wrapper {
  display: flex;
  align-items: center;
}

.account-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.login-register {
  display: flex;
  align-items: center;
  color: #ffffff;
  gap: 5px;
}

.span-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.login {
  cursor: pointer;
}

.register {
  cursor: pointer;
}

.account-information {
  color: #ffffff;
  font-family: "Vazir FD";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
}

.account-information-drop-down {
  position: absolute;
  width: 225px;
  height: 135px;
  /*height: fit-content;*/
  background-color: #070d59;
  top: 51px;
  left: 80px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  padding: 15px 20px;
  gap: 15px;
  transition: linear 0.5s;
}

.account-information-drop-down-item {
  display: flex;
  gap: 15px;
  align-items: center;
  cursor: pointer;
}
@media (min-width: 1200px) {
  li {
    padding: 18px 25px;
  }
}
</style>
